import Facebook from '../icons/social/facebook';
import Google from '../icons/social/google';
import Instagram from '../icons/social/instagram';
import Nextdoor from '../icons/social/nextdoor';
import Pinterest from '../icons/social/pinterest';
import Snapchat from '../icons/social/snapchat';
import Tiktok from '../icons/social/tiktok';
import Twitter from '../icons/social/twitter';
import Whatsapp from '../icons/social/whatsapp';
import X from '../icons/social/x';
import Youtube from '../icons/social/youtube';
import Profile from '../icons/general/Profile';
import ProfileCircle from '../icons/general/ProfileCircle';
import Badge from '../icons/general/Badge';
import Camera from '../icons/general/Camera';
import Comment from '../icons/general/Comment';
import Like from '../icons/general/Like';
import Dislike from '../icons/general/Dislike';
import Link from '../icons/general/Link';
import Send from '../icons/general/Send';
import ShareOne from '../icons/general/Share-1';
import ShareTwo from '../icons/general/Share-2';
import BookmarkOff from '../icons/general/BookmarkOff';
import Bookmark from '../icons/general/Bookmark';
import Discover from '../icons/general/Discover';
import EyeOff from '../icons/general/EyeOff';
import Eye from '../icons/general/Eye';
import Heart from '../icons/general/Heart';
import Home from '../icons/general/Home';
import News from '../icons/general/News';
import Notification from '../icons/general/Notification';
import Settings from '../icons/general/Settings';
import Star from '../icons/general/Star';
import StarOff from '../icons/general/StarOff';
import Play from '../icons/general/Play';
import Email from '../icons/general/Email';
import Replay from '../icons/general/Replay';
import ArrowRightOne from '../icons/arrow/ArrowRight_1';
import ArrowRightTwo from '../icons/arrow/ArrowRight_2';
import ArrowRightCircleOne from '../icons/arrow/ArrowRightCircle_1';
import ArrowRightCircleTwo from '../icons/arrow/ArrowRightCircle_2';
import InformationCircle from '../icons/general/InformationCircle';
import ArrowTopRight from '../icons/arrow/ArrowTopRight';
import UK from '../icons/flags/UK';
import US from '../icons/flags/US';
import Ireland from '../icons/flags/Ireland';

type IconMap = {
  [key: string]: React.ComponentType<any>;
};

const iconMap: IconMap = {
  facebook: Facebook,
  google: Google,
  instagram: Instagram,
  nextdoor: Nextdoor,
  pinterest: Pinterest,
  snapchat: Snapchat,
  tiktok: Tiktok,
  twitter: Twitter,
  whatsapp: Whatsapp,
  x: X,
  youtube: Youtube,
  profile: Profile,
  profile_circle: ProfileCircle,
  badge: Badge,
  camera: Camera,
  comment: Comment,
  link: Link,
  like: Like,
  dislike: Dislike,
  send: Send,
  share_1: ShareOne,
  share_2: ShareTwo,
  bookmark_off: BookmarkOff,
  bookmark: Bookmark,
  discover: Discover,
  eye: Eye,
  eye_off: EyeOff,
  heart: Heart,
  home: Home,
  news: News,
  notification: Notification,
  settings: Settings,
  star: Star,
  star_off: StarOff,
  play: Play,
  email: Email,
  replay: Replay,
  arrow_right_1: ArrowRightOne,
  arrow_right_2: ArrowRightTwo,
  arrow_right_circle_1: ArrowRightCircleOne,
  arrow_right_circle_2: ArrowRightCircleTwo,
  arrow_top_right: ArrowTopRight,
  information_circle: InformationCircle,
  uk: UK,
  us: US,
  ire: Ireland,
};

export default iconMap;
