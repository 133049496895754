import { SvgProps } from '@/ts/interfaces/svg';

const Dislike: React.FC<SvgProps> = ({ id, color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <g clipPath="url(#clip0_659_538258)">
      <path
        opacity="0.16"
        d="M17.3604 4H8V14C8.62474 14 9.20815 14.3122 9.5547 14.832L12.1094 18.6641C12.6658 19.4987 13.6025 20 14.6056 20H14.8195C15.4375 20 15.9075 19.4451 15.8059 18.8356L15 14H18.5604C19.8225 14 20.7691 12.8453 20.5216 11.6078L19.3216 5.60777C19.1346 4.67292 18.3138 4 17.3604 4Z"
        fill={color}
      />
      <path
        d="M17.3604 4H6C4.89543 4 4 4.89543 4 6V14H7.92963C8.59834 14 9.2228 14.3342 9.59373 14.8906L12.1094 18.6641C12.6658 19.4987 13.6025 20 14.6056 20H14.8195C15.4375 20 15.9075 19.4451 15.8059 18.8356L15 14H18.5604C19.8225 14 20.7691 12.8453 20.5216 11.6078L19.3216 5.60777C19.1346 4.67292 18.3138 4 17.3604 4Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 14V4" stroke={color} strokeWidth="2" />
    </g>
    <defs>
      <clipPath id="clip0_659_538258">
        <rect width="24" height="24" fill={color} />
      </clipPath>
    </defs>
  </svg>
);

export default Dislike;
