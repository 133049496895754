import { SvgProps } from '@/ts/interfaces/svg';

const InformationCircle: React.FC<SvgProps> = ({ id, color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <g clipPath="url(#clip0_968_42562)">
      <circle opacity="0.16" cx="12" cy="12" r="9" fill={color} />
      <circle cx="12" cy="12" r="9" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <rect x="12" y="8" width="0.01" height="0.01" stroke={color} strokeWidth="3" strokeLinejoin="round" />
      <path d="M12 12V16" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_968_42562">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default InformationCircle;
