import { SvgProps } from '@/ts/interfaces/svg';

const ArrowTopRight: React.FC<SvgProps> = ({ id, color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <g clipPath="url(#clip0_11008_228433)">
      <path d="M7 7H17M17 7V17M17 7L7 17" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_11008_228433">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowTopRight;
