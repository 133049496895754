import { SvgProps } from '@/ts/interfaces/svg';

const BookmarkOff: React.FC<SvgProps> = ({ id, color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <g clipPath="url(#clip0_11032_231068)">
      <path opacity="0.16" d="M12 17.9999L18 20.9999V17.9998L6 5.99976V20.9999L12 17.9999Z" fill={color} />
      <path
        d="M8.65704 3H16C17.1046 3 18 3.89543 18 5V12.343M6 5.99981V21L12 18L18 21V17.9998M4.00012 4L20.0001 20"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11032_231068">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BookmarkOff;
