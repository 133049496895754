import { SvgProps } from '@/ts/interfaces/svg';

const Youtube: React.FC<SvgProps> = ({ id, color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <g clipPath="url(#clip0_18276_9227)">
      <path
        d="M21.8008 8.02083C21.8008 8.02083 21.6055 6.64843 21.0039 6.04582C20.2422 5.25271 19.3906 5.24882 19 5.20217C16.2031 5 12.0039 5 12.0039 5H11.9961C11.9961 5 7.79687 5 5 5.20217C4.60938 5.24882 3.75781 5.25271 2.99609 6.04582C2.39453 6.64843 2.20312 8.02083 2.20312 8.02083C2.20312 8.02083 2 9.63427 2 11.2438V12.7523C2 14.3618 2.19922 15.9753 2.19922 15.9753C2.19922 15.9753 2.39453 17.3477 2.99219 17.9503C3.75391 18.7434 4.75391 18.7162 5.19922 18.8017C6.80078 18.9533 12 19 12 19C12 19 16.2031 18.9922 19 18.7939C19.3906 18.7473 20.2422 18.7434 21.0039 17.9503C21.6055 17.3477 21.8008 15.9753 21.8008 15.9753C21.8008 15.9753 22 14.3657 22 12.7523V11.2438C22 9.63427 21.8008 8.02083 21.8008 8.02083ZM9.93359 14.5834V8.98889L15.3359 11.7959L9.93359 14.5834Z"
        fill={color}
      />
      <path
        d="M21.8008 8.02083C21.8008 8.02083 21.6055 6.64843 21.0039 6.04582C20.2422 5.25271 19.3906 5.24882 19 5.20217C16.2031 5 12.0039 5 12.0039 5H11.9961C11.9961 5 7.79688 5 5 5.20217C4.60938 5.24882 3.75781 5.25271 2.99609 6.04582C2.39453 6.64843 2.20312 8.02083 2.20312 8.02083C2.20312 8.02083 2 9.63427 2 11.2438V12.7523C2 14.3618 2.19922 15.9753 2.19922 15.9753C2.19922 15.9753 2.39453 17.3477 2.99219 17.9503C3.75391 18.7434 4.75391 18.7162 5.19922 18.8017C6.80078 18.9533 12 19 12 19C12 19 16.2031 18.9922 19 18.7939C19.3906 18.7473 20.2422 18.7434 21.0039 17.9503C21.6055 17.3477 21.8008 15.9753 21.8008 15.9753C21.8008 15.9753 22 14.3657 22 12.7523V11.2438C22 9.63427 21.8008 8.02083 21.8008 8.02083ZM9.93359 14.5834V8.98889L15.3359 11.7959L9.93359 14.5834Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_18276_9227">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Youtube;
