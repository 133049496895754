import { SvgProps } from '@/ts/interfaces/svg';

const News: React.FC<SvgProps> = ({ id, color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <g clipPath="url(#clip0_11032_231059)">
      <path opacity="0.16" d="M3 4H17V20H5C3.89543 20 3 19.1046 3 18V4Z" fill={color} />
      <path
        d="M17 8H21V18C21 19.1046 20.1046 20 19 20M19 20C17.8954 20 17 19.1046 17 18V4H3V18C3 19.1046 3.89543 20 5 20H19ZM13 8H7M13 12H9"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11032_231059">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default News;
