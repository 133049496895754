import { SvgProps } from '@/ts/interfaces/svg';

const Replay: React.FC<SvgProps> = ({ id, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" data-testid={id}>
    <circle opacity="0.16" cx="12" cy="12" r="9" fill={color} />
    <g clipPath="url(#clip0_11008_228196)">
      <path
        d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 9.17273 4.30367 6.64996 6.34267 5M3 4.5H7V8.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11008_228196">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Replay;
