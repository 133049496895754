import { SvgProps } from '@/ts/interfaces/svg';

const Like: React.FC<SvgProps> = ({ id, color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <g clipPath="url(#clip0_11032_231105)">
      <path
        opacity="0.16"
        d="M17.3604 20H8V10C8.62474 10 9.20815 9.68777 9.5547 9.16795L12.1094 5.3359C12.6658 4.5013 13.6025 4 14.6056 4H14.8195C15.4375 4 15.9075 4.55487 15.8059 5.1644L15 10H18.5604C19.8225 10 20.7691 11.1547 20.5216 12.3922L19.3216 18.3922C19.1346 19.3271 18.3138 20 17.3604 20Z"
        fill={color}
      />
      <path
        d="M8 10V20M6 20H17.3604C18.3138 20 19.1346 19.3271 19.3216 18.3922L20.5216 12.3922C20.7691 11.1547 19.8225 10 18.5604 10H15L15.8059 5.1644C15.9075 4.55487 15.4375 4 14.8195 4H14.6056C13.6025 4 12.6658 4.5013 12.1094 5.3359L9.59373 9.1094C9.2228 9.6658 8.59834 10 7.92963 10H4V18C4 19.1046 4.89543 20 6 20Z"
        stroke={color}
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_11032_231105">
        <rect width="24" height="24" fill={color} />
      </clipPath>
    </defs>
  </svg>
);

export default Like;
