import { SvgProps } from '@/ts/interfaces/svg';

const ArrowRightCircle2: React.FC<SvgProps> = ({ id }) => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <g clipPath="url(#clip0_177_24523)">
      <rect width="16" height="12" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0V12H16V0H0Z" fill="#2E42A5" />
      <mask id="mask0_177_24523" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="12">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0V12H16V0H0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_177_24523)">
        <path
          d="M-1.78149 11.1427L1.73935 12.6317L16.08 1.61893L17.9371 -0.593806L14.1721 -1.09148L8.3229 3.65425L3.6149 6.85177L-1.78149 11.1427Z"
          fill="white"
        />
        <path d="M-1.29932 12.1859L0.494391 13.05L17.2704 -0.799421H14.7518L-1.29932 12.1859Z" fill="#F50100" />
        <path
          d="M17.7817 11.1427L14.2609 12.6317L-0.0797253 1.61893L-1.93689 -0.593806L1.82815 -1.09148L7.67734 3.65425L12.3853 6.85177L17.7817 11.1427Z"
          fill="white"
        />
        <path
          d="M17.6616 11.8914L15.8679 12.7556L8.72451 6.82588L6.60665 6.16337L-2.11557 -0.586231H0.403002L9.12032 6.00316L11.4358 6.79758L17.6616 11.8914Z"
          fill="#F50100"
        />
        <mask id="path-7-inside-1_177_24523" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.88874 -1H6.11096V4H-0.986328V8H6.11096V13H9.88874V8H17.0137V4H9.88874V-1Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.88874 -1H6.11096V4H-0.986328V8H6.11096V13H9.88874V8H17.0137V4H9.88874V-1Z"
          fill="#F50100"
        />
        <path
          d="M6.11096 -1V-2H5.11096V-1H6.11096ZM9.88874 -1H10.8887V-2H9.88874V-1ZM6.11096 4V5H7.11096V4H6.11096ZM-0.986328 4V3H-1.98633V4H-0.986328ZM-0.986328 8H-1.98633V9H-0.986328V8ZM6.11096 8H7.11096V7H6.11096V8ZM6.11096 13H5.11096V14H6.11096V13ZM9.88874 13V14H10.8887V13H9.88874ZM9.88874 8V7H8.88874V8H9.88874ZM17.0137 8V9H18.0137V8H17.0137ZM17.0137 4H18.0137V3H17.0137V4ZM9.88874 4H8.88874V5H9.88874V4ZM6.11096 0H9.88874V-2H6.11096V0ZM7.11096 4V-1H5.11096V4H7.11096ZM-0.986328 5H6.11096V3H-0.986328V5ZM0.0136719 8V4H-1.98633V8H0.0136719ZM6.11096 7H-0.986328V9H6.11096V7ZM7.11096 13V8H5.11096V13H7.11096ZM9.88874 12H6.11096V14H9.88874V12ZM8.88874 8V13H10.8887V8H8.88874ZM17.0137 7H9.88874V9H17.0137V7ZM16.0137 4V8H18.0137V4H16.0137ZM9.88874 5H17.0137V3H9.88874V5ZM8.88874 -1V4H10.8887V-1H8.88874Z"
          fill="white"
          mask="url(#path-7-inside-1_177_24523)"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_177_24523">
        <rect width="16" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowRightCircle2;
