import { SvgProps } from '@/ts/interfaces/svg';

const ArrowRightCircle2: React.FC<SvgProps> = ({ id }) => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <g clipPath="url(#clip0_177_24529)">
      <rect width="16" height="12" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11 0H16V12H11V0Z" fill="#FF8C1A" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0H6V12H0V0Z" fill="#5EAA22" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5 0H11V12H5V0Z" fill="#F7FCFF" />
    </g>
    <defs>
      <clipPath id="clip0_177_24529">
        <rect width="16" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowRightCircle2;
