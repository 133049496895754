import { SvgProps } from '@/ts/interfaces/svg';

const Email: React.FC<SvgProps> = ({ id, color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <g clipPath="url(#clip0_11032_231087)">
      <path
        opacity="0.16"
        d="M3 5L10.5858 12.5858C11.3668 13.3668 12.6332 13.3668 13.4142 12.5858L21 5V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V5Z"
        fill="currentColor"
      />
      <path
        d="M3 5H21M3 5V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V5M3 5L12 14L21 5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11032_231087">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Email;
