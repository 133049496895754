import { SvgProps } from '@/ts/interfaces/svg';

const Pinterest: React.FC<SvgProps> = ({ id, color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <path
      d="M12 2C6.47716 2 2 6.47718 2 12C2 16.2365 4.63582 19.8581 8.35572 21.3152C8.26834 20.524 8.18931 19.3072 8.39039 18.4435C8.57213 17.663 9.56301 13.4728 9.56301 13.4728C9.56301 13.4728 9.26389 12.8737 9.26389 11.9881C9.26389 10.5976 10.0698 9.55951 11.0733 9.55951C11.9265 9.55951 12.3386 10.2 12.3386 10.9681C12.3386 11.8261 11.7923 13.1089 11.5104 14.2977C11.2748 15.293 12.0096 16.1047 12.9913 16.1047C14.7686 16.1047 16.1348 14.2306 16.1348 11.5255C16.1348 9.13112 14.4144 7.45708 11.9577 7.45708C9.11241 7.45708 7.4423 9.59124 7.4423 11.7968C7.4423 12.6563 7.77339 13.5779 8.18653 14.0789C8.26825 14.1779 8.28022 14.2647 8.25588 14.3657C8.17997 14.6816 8.01127 15.3608 7.97815 15.4998C7.93455 15.6829 7.83323 15.7217 7.64371 15.6335C6.39478 15.0522 5.61391 13.2261 5.61391 11.7595C5.61391 8.60502 7.90586 5.70803 12.2213 5.70803C15.6903 5.70803 18.3861 8.18 18.3861 11.4836C18.3861 14.9299 16.2131 17.7036 13.197 17.7036C12.1837 17.7036 11.2311 17.1771 10.905 16.5553C10.905 16.5553 10.4036 18.4646 10.282 18.9325C10.0562 19.8011 9.44677 20.8898 9.03912 21.5541C9.97475 21.8438 10.9691 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47718 17.5228 2 12 2Z"
      fill={color}
    />
  </svg>
);

export default Pinterest;
