import { SvgProps } from '@/ts/interfaces/svg';

const ProfileCircle: React.FC<SvgProps> = ({ id, color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <g clipPath="url(#clip0_11032_231109)">
      <path
        opacity="0.16"
        d="M15 15H8.99998C7.18822 15 5.6578 16.2045 5.16583 17.8564C6.81645 19.7808 9.26583 21 12 21C14.7341 21 17.1835 19.7808 18.8341 17.8564C18.3421 16.2045 16.8117 15 15 15Z"
        fill={color}
      />
      <path
        d="M4.52609 17.0157L5.35593 16.4577L4.52609 17.0157ZM19.4739 17.0157L18.6441 16.4577L19.4739 17.0157ZM5.16583 17.8564L4.20743 17.571L4.05179 18.0936L4.4068 18.5074L5.16583 17.8564ZM18.8341 17.8564L19.5932 18.5074L19.9482 18.0936L19.7925 17.571L18.8341 17.8564ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM12 20C9.23327 20 6.794 18.5964 5.35593 16.4577L3.69625 17.5737C5.49004 20.2414 8.53946 22 12 22V20ZM5.35593 16.4577C4.49965 15.1843 4 13.652 4 12H2C2 14.0618 2.62509 15.9807 3.69625 17.5737L5.35593 16.4577ZM20 12C20 13.652 19.5003 15.1843 18.6441 16.4577L20.3038 17.5737C21.3749 15.9807 22 14.0618 22 12H20ZM18.6441 16.4577C17.206 18.5964 14.7667 20 12 20V22C15.4605 22 18.51 20.2414 20.3038 17.5737L18.6441 16.4577ZM13 9C13 9.55228 12.5523 10 12 10V12C13.6569 12 15 10.6569 15 9H13ZM12 10C11.4477 10 11 9.55228 11 9H9C9 10.6569 10.3431 12 12 12V10ZM11 9C11 8.44772 11.4477 8 12 8V6C10.3431 6 9 7.34315 9 9H11ZM12 8C12.5523 8 13 8.44772 13 9H15C15 7.34315 13.6569 6 12 6V8ZM8.99998 16H15V14H8.99998V16ZM8.99998 14C6.73381 14 4.82218 15.5068 4.20743 17.571L6.12423 18.1418C6.49342 16.9022 7.64264 16 8.99998 16V14ZM12 20C9.56988 20 7.39361 18.9177 5.92487 17.2054L4.4068 18.5074C6.23929 20.6439 8.96183 22 12 22V20ZM15 16C16.3573 16 17.5065 16.9022 17.8757 18.1418L19.7925 17.571C19.1778 15.5068 17.2661 14 15 14V16ZM18.0751 17.2054C16.6063 18.9177 14.4301 20 12 20V22C15.0382 22 17.7607 20.6439 19.5932 18.5074L18.0751 17.2054Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_11032_231109">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ProfileCircle;
