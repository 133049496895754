import { SvgProps } from '@/ts/interfaces/svg';

const Heart: React.FC<SvgProps> = ({ id, color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <g clipPath="url(#clip0_11032_231079)">
      <path
        opacity="0.16"
        d="M19.0711 13.1421L13.4142 18.799C12.6332 19.58 11.3668 19.58 10.5858 18.799L4.92894 13.1421C2.97632 11.1895 2.97632 8.02369 4.92894 6.07106C6.88157 4.11844 10.0474 4.11844 12 6.07106C13.9526 4.11844 17.1185 4.11844 19.0711 6.07106C21.0237 8.02369 21.0237 11.1895 19.0711 13.1421Z"
        fill={color}
      />
      <path
        d="M19.071 13.1421L13.4141 18.799C12.6331 19.58 11.3667 19.58 10.5857 18.799L4.92882 13.1421C2.9762 11.1895 2.9762 8.02366 4.92882 6.07103C6.88144 4.11841 10.0473 4.11841 11.9999 6.07103C13.9525 4.11841 17.1183 4.11841 19.071 6.07103C21.0236 8.02366 21.0236 11.1895 19.071 13.1421Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11032_231079">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Heart;
