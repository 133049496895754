import { SvgProps } from '@/ts/interfaces/svg';

const StarOff: React.FC<SvgProps> = ({ id, color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <g clipPath="url(#clip0_11032_231071)">
      <path
        opacity="0.16"
        d="M12 18.28L18.4656 21.8992L17.6142 17.6141L8.74632 8.74619L1.53839 9.6008L6.97843 14.6316L5.53437 21.8992L12 18.28Z"
        fill={color}
      />
      <path
        d="M8.74632 8.7462L1.53839 9.60081L6.97843 14.6316L5.53437 21.8992L12 18.28L18.4656 21.8992L17.6142 17.6141M10.6291 4.97208L12 2L15.1035 8.72839L22.4616 9.60081L18.719 13.0619M4.00012 4L20.0001 20"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11032_231071">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default StarOff;
