import { SvgProps } from '@/ts/interfaces/svg';

const Share2: React.FC<SvgProps> = ({ id, color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <g clipPath="url(#clip0_11032_231100)">
      <path
        d="M15 4.99996L12 1.99995M12 1.99995L9 4.99995M12 1.99995L12 14M6 8.99996H4V18C4 19.1045 4.89543 20 6 20H18C19.1046 20 20 19.1045 20 18V8.99996H18"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11032_231100">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Share2;
