import { SvgProps } from '@/ts/interfaces/svg';

const Google: React.FC<SvgProps> = ({ id, color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={id}>
    <g clipPath="url(#clip0_11032_234113)">
      <path
        d="M11.956 10.356V13.807H16.748C16.302 16 14.435 17.26 11.956 17.26C9.06857 17.2202 6.74868 14.8682 6.74868 11.9805C6.74868 9.09275 9.06857 6.74072 11.956 6.70098C13.1562 6.69954 14.3195 7.11605 15.246 7.87898L17.846 5.27898C14.8636 2.65705 10.508 2.31981 7.15758 4.45142C3.80713 6.58303 2.26705 10.6712 3.37827 14.4836C4.4895 18.296 7.98497 20.9164 11.956 20.914C16.423 20.914 20.485 17.665 20.485 11.98C20.4781 11.4326 20.4111 10.8877 20.285 10.355L11.956 10.356Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_11032_234113">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Google;
